import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error('An error occurred:', error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    return throwError(
      'Something bad happened; please try again later.');
  }

  reportsData(t, d, m) {
    const currentuser = JSON.parse(sessionStorage.getItem('token'));
    const body = {
      empId: currentuser.results[0]?.EmployeeID,
      employeeName: currentuser.results[0]?.EmployeeFirstName,
      title: t,
      description: d,
      module: m
    }
    this.http.post(this.BaseUrl + '/insert/reports', body, httpOptions).pipe(
      catchError(this.handleError)
    ).subscribe((res: any) => {
      if (res.status && res.status_Code == 200) {
        console.log(res.message)
      }
    })
  }

}

