<mat-toolbar style="height: 70px;background: #639d8e7a;">
    <span style="font-size: 16px;"> <img style="width: auto; height: 44px;" src="assets/raj-print-logo.png" alt="logo"></span>
    <span class="example-spacer"></span>
   
</mat-toolbar>
<section class="mat-typography" style="padding: 20px;">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
            <div>
                <img src="../../assets/channel-partner.png" style="width: 100%;">
            </div>
        </div>
        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" >
            <mat-card style="padding: 14px 24px 14px 24px !important;border-radius: 10px;margin-top:4.5rem !important;">
                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="default" color="#fff" type="timer" [fullScreen]="false">
                </ngx-spinner>
                <div class="text-center">
                    <h2><b style="border-bottom: solid #93B946 2px;">Channal Partner</b></h2>
                </div>
                <form [formGroup]="vendersFormsdata">
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>Firm Name / Vender</mat-label>
                                <input matInput type="text" placeholder="Firm Name / Vender" formControlName="firm_name" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>Company Address</mat-label>
                                <input matInput type="text" placeholder="Company Address" formControlName="company_address">
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>District</mat-label>
                                <input matInput type="text" placeholder="District" formControlName="district" required>
                            </mat-form-field>
                        </div>
                    </div>
        
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>State</mat-label>
                                <input matInput type="text" placeholder="State" formControlName="state" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>GST</mat-label>
                                <input matInput type="text" placeholder="GST(Optional)" formControlName="do_you_have_gst">
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>Office Contact Number</mat-label>
                                <input matInput type="text" placeholder="Office Contact Number" formControlName="office_contact"
                                    (keypress)="keyPressNumbers($event)" maxlength="10" required>
                            </mat-form-field>
                        </div>
                    </div>
        
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>Email Address</mat-label>
                                <input matInput type="email" placeholder="Email Address" formControlName="email">
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>Contact Person Name</mat-label>
                                <input matInput type="text" placeholder="Contact Person Name" formControlName="contact_person_name" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="33" fxFlex.lg="33" fxFlex.xl="33">
                            <mat-form-field appearance="outline">
                                <mat-label>Contact Person Mobile</mat-label>
                                <input matInput type="text" placeholder="Contact Person Mobile"
                                    formControlName="contact_person_mobile" (keypress)="keyPressNumbers($event)" maxlength="10"
                                    required>
                            </mat-form-field>
                        </div>
                    </div>
        
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
                            <mat-form-field appearance="outline">
                                <mat-label>Service Type</mat-label>
                                <mat-select formControlName="service_type" required>
                                    <mat-option value="Manufacturer">Manufacturer</mat-option>
                                    <mat-option value="Contractor">Contractor</mat-option>
                                    <mat-option value="Self Skilled">Self Skilled</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
                            <mat-form-field appearance="outline">
                                <mat-label>Primary Service</mat-label>
                                <input matInput type="text" placeholder="Primary Service" formControlName="primary_services"
                                    required>
                            </mat-form-field>
                        </div>
                                
                    </div>
        
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
                            <mat-form-field appearance="outline">
                                <mat-label>Other Services</mat-label>
                                <input matInput type="text" placeholder="Other Services" formControlName="service_Description" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
                            <mat-form-field appearance="outline">
                                <mat-label>Working Area</mat-label>
                                <input matInput type="text" placeholder="Working Area" formControlName="working_area" required>
                            </mat-form-field>
                        </div>
        
                    </div>
        
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%" style="margin-top: 0.5em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
                            <p>Work Image</p>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="30" fxFlex.lg="30" fxFlex.xl="30"
                                    class="text-left">
                                    <button type="submit" class="btn btn-success btn-lg btn-block"
                                        (click)="fileInputWorking.click()">Upload</button>
                                </div>
                                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="70" fxFlex.lg="70" fxFlex.xl="70">
                                    <div>
                                        <div class="avatar-upload" style="display: flex;">
                                            <img *ngFor='let url of imagess' [src]="url"
                                                style="margin: 3px;width: 60%;display: flex;">
                                            <div class="avatar-edit">
                                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInputWorking
                                                    (change)="uploadFileWorking($event)" multiple />
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50">
                            <p>Profile Cat-log</p>
                            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%">
                                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="30" fxFlex.lg="30" fxFlex.xl="30"
                                    class="text-left">
                                    <button type="submit" class="btn btn-success btn-lg btn-block"
                                        (click)="fileInput.click()">Upload</button>
                                </div>
                                <div fxFlex.xs="100" fxFlex.sm="100" fxFlex.md="70" fxFlex.lg="70" fxFlex.xl="70">
                                    <div>
                                        <div class="avatar-upload" style="display: flex;">
                                            <img *ngFor='let url of images' [src]="url"
                                                style="margin: 3px;width: 60%;display: flex;">
                                            <div class="avatar-edit">
                                                <input type='file' id="imageUpload" accept=".png, .jpg, .jpeg" #fileInput
                                                    (change)="uploadFile($event)" multiple />
                                            </div>
        
                                        </div>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                    </div>
        
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="0.5%"
                        style="margin-top: 0.5em; margin-bottom: 1em;">
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50"> </div>
                        <div fxFlex.xs="50" fxFlex.sm="50" fxFlex.md="50" fxFlex.lg="50" fxFlex.xl="50" class="text-right">
                            <button mat-raised-button color="primary" [disabled]="!vendersFormsdata.valid"
                                (click)="submitedLeadData()">Submit</button>
                        </div>
                    </div>
                </form>
            </mat-card>
        </div>
    </div>
   
</section>