<h2 mat-dialog-title class="errortitle">Info</h2>
<mat-dialog-content>
    <div class="errorImage">
        <img src="../../../assets/alert/confrtmations.gif">
    </div>
    <div class="errorMessage">
        <p>{{message}}</p>
    </div>
    <div class="errorButton">
        <button mat-raised-button color="primary" (click)="closedPopup(false)">No</button>&nbsp;&nbsp;
       <button mat-raised-button color="warn" (click)="closedPopup(true)">Yes</button>
    </div>
</mat-dialog-content>