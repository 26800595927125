import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FieldGuard implements CanActivate {
  constructor(private Router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentuser = JSON.parse(sessionStorage.getItem('token'))
    if(currentuser != '' && currentuser != undefined && currentuser != null){
      if (currentuser.Status == true && currentuser.EmployeeType != 'ad@&$%min') {
        return true;
      }else{
        this.Router.navigate(['/auth']);
        return false;
      }
    }else{
      this.Router.navigate(['/auth']);
      return false;
    }
  }
}
