import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-confermation-dialog',
  templateUrl: './custom-confermation-dialog.component.html',
  styleUrls: ['./custom-confermation-dialog.component.scss']
})
export class CustomConfermationDialogComponent implements OnInit {
  message: any = '';
  constructor(
    public dialogRef: MatDialogRef<CustomConfermationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.message = this.data.message;
  }
  ngOnInit(): void {}
  
  closedPopup(ele:boolean) {
    this.dialogRef.close(ele);
  }

}
