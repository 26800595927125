import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { ReportsService } from "./services/reports.service";
import { Router } from '@angular/router';
const MINUTES_UNITL_AUTO_LOGOUT = 75 // in mins
const CHECK_INTERVAL = 1000 // in ms
const STORE_KEY = 'lastAction';
declare var device;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  statusCallApi = true;
  loading: boolean;
  constructor(
    private ReportsService:ReportsService,
    private Router:Router,
  ) {
    this.initListener();
    this.initInterval();
    sessionStorage.setItem(STORE_KEY, Date.now().toString());
    document.addEventListener('deviceready', function () {
      alert(device.platform);
    }, false);
  }
  public getLastAction() {
    return parseInt(sessionStorage.getItem(STORE_KEY));
  }
  public setLastAction(lastAction: number) {
    sessionStorage.setItem(STORE_KEY, lastAction.toString());
  }


  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    if (isTimeout) {
      if(this.statusCallApi){
        this.statusCallApi = false;
        this.ReportsService.reportsData('User Logout', 'No Activity On Portal before 75', 'Auth');
        Swal.fire("Warning", `Your session expired. Please login again`, "warning").then(ok => {
          localStorage.clear();
          sessionStorage.clear();
          return this.Router.navigate(['/auth'])
        });
      }

    }
  }
}