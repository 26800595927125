<h2 mat-dialog-title class="errortitle">Error</h2>
<mat-dialog-content>
    <div class="errorImage">
        <img src="../../../assets/alert/error.gif">
    </div>
    <div class="errorMessage">
        <p>{{message}}</p>
    </div>
    <div class="errorButton">
       <button mat-raised-button color="primary" (click)="closedPopup()">Ok</button>
    </div>
</mat-dialog-content>