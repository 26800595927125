import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

import { RouterTabs } from './router-tab/router-tabs.directive';
import { RouterTab } from './router-tab/router-tab.directive';
import { LoaderComponent } from './loader/loader.component';
import { CustomConfermationDialogComponent } from './custom-confermation-dialog/custom-confermation-dialog.component';
import { CustomSuccessDialogComponent } from './custom-success-dialog/custom-success-dialog.component';
import { CustomErrorDialogComponent } from './custom-error-dialog/custom-error-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
  ],
  declarations: [
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    LoaderComponent,
    CustomConfermationDialogComponent,
    CustomSuccessDialogComponent,
    CustomErrorDialogComponent,
  ],
  exports: [
    CommonModule,
    FlexLayoutModule,
    PageNotFoundComponent,
    RouterTabs,
    RouterTab,
    LoaderComponent,
    CustomConfermationDialogComponent,
    CustomSuccessDialogComponent,
    CustomErrorDialogComponent,
  ],
})
export class SharedModule {}
