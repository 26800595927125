import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class LoaderservicesService {
  public isLoading = new BehaviorSubject(false);
  constructor() { }

}

