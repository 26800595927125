import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-error-dialog',
  templateUrl: './custom-error-dialog.component.html',
  styleUrls: ['./custom-error-dialog.component.scss']
})
export class CustomErrorDialogComponent implements OnInit {
  message: any = '';
  constructor(
    public dialogRef: MatDialogRef<CustomErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.message = this.data.message;
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.closedPopup();
    }, 2000);
  }
  closedPopup() {
    this.dialogRef.close();
  }

}
