<h2 mat-dialog-title class="successtitle">Success</h2>
<mat-dialog-content>
    <div class="successImage">
        <img src="../../../assets/alert/verified.gif">
    </div>
    <div class="successMessage">
        <p>{{message}}</p>
    </div>
    <div class="successButton" >
       <button mat-raised-button color="primary" (click)="closedPopup()">Ok</button>
    </div>
</mat-dialog-content>
