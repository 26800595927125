import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}


@Injectable({
  providedIn: 'root'
})
export class SettingServiceService {

  BaseUrl: any = environment.BaseUrl;
  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

  getAllItemsData() {
    return this.http.get(this.BaseUrl + '/all/fetch/items', httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  postAllItems(body) {
    return this.http.post(this.BaseUrl + '/insert/items', body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  putSingleItems(body) {
    return this.http.post(this.BaseUrl + '/update/items', body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  putSingleStatusItems(body) {
    return this.http.post(this.BaseUrl + '/update/items/status', body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  deleteSingleItems(body) {
    return this.http.post(this.BaseUrl + '/delete/items', body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  commonPostData(body){
    return this.http.post(this.BaseUrl + '/update/items/status', body, httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  commonPostDataFormData(url, body) {
    return this.http.post(this.BaseUrl + url, body).pipe(
      catchError(this.handleError)
    );
  }

  commonPostDataForPost(url, body){
    return this.http.post(this.BaseUrl + url,body,{  
      reportProgress: true,  
      observe: 'events'  
    }).pipe(
      catchError(this.handleError)
    );
  }
  commonGetDataForGet(url){
    return this.http.get(this.BaseUrl + url, httpOptions).pipe(
      catchError(this.handleError)
    );
  }
  commonpost(url, body){
    return this.http.post(this.BaseUrl + url, body,httpOptions).pipe(
      catchError(this.handleError)
    );
  }
}
