import { Component, OnInit, Inject, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SettingServiceService } from 'src/app/services/setting-service.service';
import { MatDialog } from '@angular/material/dialog';
import { CustomSuccessDialogComponent } from '../shared/custom-success-dialog/custom-success-dialog.component';
import { CustomErrorDialogComponent } from '../shared/custom-error-dialog/custom-error-dialog.component';
@Component({
  selector: 'app-vender-create',
  templateUrl: './vender-create.component.html',
  styleUrls: ['./vender-create.component.scss'],
})
export class VenderCreateComponent implements OnInit {
  vendersFormsdata: FormGroup;
  @ViewChild('fileInput') el!: ElementRef;
  imageUrl: any = 'https://i.ibb.co/fDWsn3G/buck.jpg';
  editFile: boolean = true;
  removeUpload: boolean = false;
  emailRegEx = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';

  images: any = [];
  imgcatlog: any;
  imagess: any = [];
  img;
  constructor(
    private _formBuilder: FormBuilder,
    private settingSer: SettingServiceService,
    private cd: ChangeDetectorRef,
    private ngxSpinner: NgxSpinnerService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.vendersFormsdata = this._formBuilder.group({
      user_id: [''],
      firm_name: ['', [Validators.required]],
      company_address: ['', [Validators.required]],
      district: ['', [Validators.required]],
      state: ['', [Validators.required]],
      do_you_have_gst: [''],
      office_contact: ['', [Validators.required]],
      email: [''],
      contact_person_name: ['', [Validators.required]],
      contact_person_mobile: ['', [Validators.required]],
      primary_services: ['', [Validators.required]],
      service_Description: ['', [Validators.required]],
      service_type: ['', [Validators.required]],
      working_area: ['', [Validators.required]],
    });
  }

  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  uploadFile(event: any) {
    this.imgcatlog = event.target.files;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  uploadFileWorking(event) {
    this.img = event.target.files;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.imagess.push(event.target.result);
        };
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  submitedLeadData() {
    let body = this.vendersFormsdata.value;
    this.settingSer
      .commonpost('/vender/creates', body)
      .subscribe((res: any) => {
        if (res.status && res.status_Code == 200) {
          this.dialog.open(CustomSuccessDialogComponent, {
            data: { message: 'Data Add Successfull' },
            width: '400px',
          });
          this.vendersFormsdata.reset();
          this.submitedWorkingImage(res.data);
          setTimeout(() => {
            this.dialog.ngOnDestroy();
            this.submitedCatlogImage(res.data);
          }, 1000);
        }
      },error=>{
        this.dialog.open(CustomErrorDialogComponent, {
          data: { message: 'Something is went wrong. Please Try again.' },
          width: '400px',
        });
      });
  }

  submitedWorkingImage(data) {
    for (let index = 0; index < this.img.length; index++) {
      const formData = new FormData();
      const element = this.img[index];
      formData.append('img', element);
      formData.append('namesImage', 'Working');
      formData.append('vids', data);
      this.settingSer
        .commonPostDataForPost('/upload/Image/working', formData)
        .subscribe((res: any) => {
          if (res.status && res.status_Code == 200) {
            this.images = [];
            this.imagess = [];
          }
        });
    }
  }
  submitedCatlogImage(data) {
    for (let index = 0; index < this.imgcatlog.length; index++) {
      const element = this.imgcatlog[index];
      const formData = new FormData();
      formData.append('img', element);
      formData.append('namesImage', 'Catlog');
      formData.append('vids', data);
      this.settingSer
        .commonPostDataForPost('/upload/Image/catlogs', formData)
        .subscribe((res: any) => {
          if (res.status && res.status_Code == 200) {
            this.images = [];
            this.imagess = [];
          }
        });
    }
  }
}
