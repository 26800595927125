import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AuthGuard } from './core/auth.guard';
import { SalesGuard } from './core/sales.guard'
import { FieldGuard } from "./core/Field.guard";
import { AdminGuard } from "./core/admin.guard";
import { VenderCreateComponent } from './vender-create/vender-create.component';
const routes: Routes = [
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('./session/session.module').then(m => m.sessionModule)
  },
  
  // {
  //   path: '',
  //   loadChildren: () => import('./ManagerPanel/ManagerPanel.module').then(m => m.ManagerPanelModule),
  //   canActivate: [AuthGuard]
  // },

  {
    path: 'Sales',
    loadChildren: () => import('./EmployeePanel/EmployeePanel.module').then(m => m.EmployeePanelModule),
    // canActivate: [SalesGuard]
  },
  {
    path: 'Field',
    loadChildren: () => import('./FieldEmployeePanel/FieldEmployeePanel.module').then(m => m.FieldEmployeePanelModule),
    canActivate: [FieldGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin-panel/admin-panel.module').then(m => m.AdminPanelModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'emp',
    loadChildren: () => import('./raj-print-employees/raj-print-employees.module').then(m => m.RajPrintEmployeesModule),
    // canActivate: [SalesGuard]
  },
  {
    path: 'superwiser',
    loadChildren: () => import('./superwisers/superwiser-panel.module').then(m => m.SuperwiserPanelModule),

  },
  {
    path: 'employees',
    loadChildren: () => import('./employees/employees-panel.module').then(m => m.EmployeesPanelModule),

  },

  {
    path: 'order-track',
    loadChildren: () => import('./order-tracker/order-tracker.module').then(m => m.OrderTrackerModule)
  },
  {
    path: 'channal-partner',
    loadChildren: () => import('./vender/vender.module').then(m => m.VenderModule)
  },
  {
    path: 'channal-partner-create',
    component:VenderCreateComponent
  },
  {

    path:'**', redirectTo: '/auth'

  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
