import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-success-dialog',
  templateUrl: './custom-success-dialog.component.html',
  styleUrls: ['./custom-success-dialog.component.scss']
})
export class CustomSuccessDialogComponent implements OnInit {
  message: any = '';
  constructor(
    public dialogRef: MatDialogRef<CustomSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {
    this.message = this.data.message;
  }
  ngOnInit(): void {
    setTimeout(() => {
      this.closedPopup();
    }, 2000);
  }
  closedPopup() {
    this.dialogRef.close();
  }
}
