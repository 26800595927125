import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderservicesService } from 'src/app/loaderservices.service';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  loading: boolean;
  constructor(
    private loaderService: LoaderservicesService,
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
   }

  ngOnInit(): void {
  }

}
